@import "../../global.scss";

.work {
  background-color: crimson;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  
  
  .arrow {
    height: 50px;
    position: absolute;
    cursor: pointer;
    @include mobile{
      display: none;
     }

    &.left {
      left: 100px;
      transform: rotateY(180deg);
    }

    &.right {
      right: 100px;
    }
  }

  .slider {
    height: 350px;
    display: flex;
    position: absolute;
    left: 0;
    transition: all 1s ease-out;
    @include mobile{
      height:100vh;
      flex-direction: column;
      justify-content: center;
     }

    .container {
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;

      .item {
        width: 700px;
        height: 100%;
        background-color: white;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include mobile{
          width:80%;
          height: 150px;
          height: 15px 0;
         }


        .left {
          flex: 4;
          height: 80%;
          display: flex;
          align-items: center;
          justify-content: center;

          .leftContainer {
            width: 90%;
            height: 70%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            
            .imgContainer {
              
            @include mobile{
              display: none;
              width:100%;
              height: 100%;
            }
      img{
  width: 150px;
  height: 150px;
  @include mobile{
     
    width:15px;
    
  }
}
            }
            h2 {
              font-size: 20px;
              justify-content: center;
              @include mobile{
                font-size: 13px;
              }
            }
            span {
              font-size: 12px;
              font-weight: 400;
              text-decoration: navajowhite;
              
              cursor: pointer;
              @include mobile{
                display: none;
              }
            }
          }
        }
        .right {
          flex: 8;
          height: 80%;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;

         
          span{
            @include mobile{
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}